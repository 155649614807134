import React from 'react';
import styles from './Footer.module.scss';
import Logo from '../Logo';
import { Link } from 'react-router-dom';

const Footer = () => (
    <div
        className={styles.container}
    >
        <div className={styles.shadow} />
        <div
            className={styles.navbarBackground}
        >
            <Link to="/">
                <div className={styles.text}>
                    Home
                </div>
            </Link>
            <Link to="/terms">
                <div className={styles.text}>
                    Terms and conditions
                </div>
            </Link>
            <Link to="/privacy">
                <div className={styles.text}>
                    Privacy Policy
                </div>
            </Link>
            <Link to="/cookies">
                <div className={styles.text}>
                    Cookies Policy
                </div>
            </Link>
            <Link to="/data-deletion">
                <div className={styles.text}>
                    Data Deletion
                </div>
            </Link>
            <Link to="/contact">
                <div className={styles.text}>
                    Contact
                </div>
            </Link>
        </div>
    </div>
);

export default Footer;
