import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeLandingPage from '../screens/HomeLandingPage';
import TermsAndConditions from '../screens/TermsAndConditions';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import CookiesPolicy from '../screens/CookiesPolicy';
import DataDeletion from '../screens/DataDeletion';
import Contact from '../screens/Contact';

const AppNavigator = () => (
    <Routes>
        <Route path="/" element={<HomeLandingPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/cookies" element={<CookiesPolicy />} />
        <Route path="/data-deletion" element={<DataDeletion />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<HomeLandingPage />} />
    </Routes>
);

export default AppNavigator;
