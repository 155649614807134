import React from 'react';
import styles from './HomeLandingPage.module.scss';
import PhoneFrame from '../assets/images/Iphone-14-Pro-frame.png';
import ScreenRecording from '../assets/videos/ScreenRec.mov';
import AndroidQRCode from '../assets/images/android-qr-code-app.png';
import IOSQRCode from '../assets/images/ios-qr-code-app.png';
import Background from '../components/layout/Background';

const features = [
    '\u2022 12 Workout guides',
    '\u2022 60 Workouts to fit your needs',
    '\u2022 163 Unique Video Exercises',
    '\u2022 Adjustable Difficulty',
    '\u2022 Home workouts',
    '\u2022 No phone touch during workouts',
    '\u2022 No equipment needed',
];

const HomeLandingPage = () => (
    <div className={styles.container}>
        <Background />
        <div
            className={styles.phoneContainer}
        >
            <img
                className={styles.phoneFrame}
                src={PhoneFrame}
            />
            <video
                className={styles.videoRec}
                autoPlay
                loop
                muted
                src={ScreenRecording}
            />
        </div>
        <div
            className={styles.contentContainer}
        >
            <div className={styles.title}>
                Welcome to Little
                <span className={styles.green}>bit</span>
                of
                <span className={styles.green}>Workout</span>
            </div>
            <div className={styles.description}>
                A simple app that will guide you on the journey to become healthy and fit.
                <br />
                <br />
                <div className={styles.rowContainer}>
                    <div
                        className={styles.qrCodeContainer}
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.littlebitofuniverse.littlebitofworkout')}
                    >
                        <img
                            className={styles.qrCode}
                            src={AndroidQRCode}
                        />
                        <div
                            className={styles.qrCodeText}
                        >
                            {'Download from\nGoogle Play'}
                        </div>
                    </div>
                    <div
                        className={styles.featuresContainer}
                    >
                        <div className={styles.featuresTitle}>
                            App features:
                        </div>
                        {features.map((feature) => (
                            <div
                                key={feature}
                                className={styles.feature}
                            >
                                {feature}
                            </div>
                        ))}
                    </div>
                    <div
                        className={styles.qrCodeContainer}
                        onClick={() => window.open('https://apps.apple.com/gb/app/little-bit-of-workout/id6578452623')}
                    >
                        <img
                            className={styles.qrCode}
                            src={IOSQRCode}
                        />
                        <div
                            className={styles.qrCodeText}
                        >
                            {'Download from\nApp Store'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default HomeLandingPage;
